const modalBtns = document.querySelectorAll('#modal-btn')
const modalBlock = document.querySelector('#modal-block')
const modalOverlay = document.querySelector('#modal-overlay')
const modalBuild = document.querySelector('.modal-build')
const closeBtn = document.querySelector('.close')

for (modalBtn of modalBtns) {
    modalBtn.addEventListener('click', (e) => {
        e.preventDefault()
        modalBlock.classList.add('open')
        modalOverlay.classList.add('open')
    })
}

modalOverlay.addEventListener('click', (e) => {
    e.preventDefault()
    modalBlock.classList.remove('open')
    modalOverlay.classList.remove('open')
})

modalBuild.addEventListener('click', (e) => {
    e.preventDefault()
    modalBlock.classList.remove('open')
    modalOverlay.classList.remove('open')
})

closeBtn.addEventListener('click', (e) => {
    e.preventDefault()
    modalBlock.classList.remove('open')
    modalOverlay.classList.remove('open')
})