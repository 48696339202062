$(function() {
    var $menu_popup = $('.slide-menu');
    var disabledClick = false;
    var animationSpeed = 1000;
 
    $("#nav-ico").click(function(evt){
      evt.stopPropagation();

      if(disabledClick){
        return  false
      }

      disabledClick = true;

      setTimeout(function () {
          disabledClick = false;
      }, animationSpeed);

      $('body').addClass('body_pointer');	
      $menu_popup.show(0);
      $menu_popup.animate(
        {left: parseInt($menu_popup.css('left'),10) == 0 ? -$menu_popup.outerWidth() : 0}, 
        300, function(){
          disabledClick = false
        });
      return false;
      
    });	
    
    $(".menu-close").click(function(){
      $('body').removeClass('body_pointer');
      $menu_popup.animate(
        {left: parseInt($menu_popup.css('left'),10) == 0 ? -$menu_popup.outerWidth() : 0}, 
        300, 
        function(){
          $menu_popup.hide(0);
        }
      );
      return false;
    });	
    
    // $(document).on('click', function(e){
    //   if (!$(e.target).closest('.menu-popup').length){
    //     $('body').removeClass('body_pointer');
    //     $menu_popup.animate(
    //       {left: parseInt($menu_popup.css('left'),10) == 0 ? -$menu_popup.outerWidth() : 0}, 
    //       300, 
    //       function(){
    //         $menu_popup.hide(0);
    //       }
    //     );
    //   }
    // });
});